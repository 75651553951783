<template>
    <div class="row">
        <div class="col-xl-6">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ title.ucFirst() }}</h4>
                </div>
                <div class="card-body">
                    <form>
                        <b-overlay :show="loading" class="p-1" rounded>
                            <div class="row mb-4">
                                <cropper
                                    v-if="model.image_file && cropper"
                                    v-model="model.image"
                                    :file="model.image_file"
                                    @update:file="(file) => model.image_file = file"
                                ></cropper>
                                <label
                                    :class="{'text-danger': states.image_file === false}"
                                    class="col-sm-3 col-form-label"
                                    for="image-file-input"
                                >
                                    <img v-if="model.image" :src="model.image" alt="" class="rounded-circle avatar-lg"/>
                                    <div v-else class="avatar-sm">
                                        <span class="avatar-title rounded-circle bg-primary text-white font-size-16"> {{
                                                letter
                                            }} </span>
                                    </div>
                                </label
                                >
                                <div class="col-sm-9 align-items-center d-flex">
                                    <input
                                        id="image-file-input"
                                        :class="{'border-danger': states.image_file === false}"
                                        class="form-control"
                                        name="image_file"
                                        type="file"
                                        @change="uploadImage"
                                    />
                                    <div :class="{'d-block': states.image_file === false}" class="invalid-feedback">
                                        {{ errors.image_file }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label
                                    :class="{'text-danger': states.prefix === false}"
                                    class="col-sm-3 col-form-label"
                                    for="prefix-input"
                                >{{ $t('users.columns.prefix').ucFirst() }}</label
                                >
                                <div class="col-sm-9">
                                    <input
                                        id="prefix-input"
                                        v-model="model.prefix"
                                        :class="{'border-danger': states.prefix === false}"
                                        :placeholder="$t('users.placeholders.prefix').ucFirst()"
                                        class="form-control"
                                        name="prefix"
                                        type="text"
                                    />
                                    <div :class="{'d-block': states.prefix === false}" class="invalid-feedback">
                                        {{ errors.prefix }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label
                                    :class="{'text-danger': states.firstname === false}"
                                    class="col-sm-3 col-form-label"
                                    for="firstname-input"
                                >{{ $t('users.columns.firstname').ucFirst() }}</label
                                >
                                <div class="col-sm-9">
                                    <input
                                        id="firstname-input"
                                        v-model="model.firstname"
                                        :class="{'border-danger': states.firstname === false}"
                                        :placeholder="$t('users.placeholders.firstname')"
                                        class="form-control"
                                        name="firstname"
                                        type="text"
                                    />
                                    <div :class="{'d-block': states.firstname === false}" class="invalid-feedback">
                                        {{ errors.firstname }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label
                                    :class="{'text-danger': states.lastname === false}"
                                    class="col-sm-3 col-form-label"
                                    for="lastname-input"
                                >{{ $t('users.columns.lastname').ucFirst() }}</label
                                >
                                <div class="col-sm-9">
                                    <input
                                        id="lastname-input"
                                        v-model="model.lastname"
                                        :class="{'border-danger': states.lastname === false}"
                                        :placeholder="$t('users.placeholders.lastname').ucFirst()"
                                        class="form-control"
                                        name="lastname"
                                        type="text"
                                    />
                                    <div :class="{'d-block': states.lastname === false}" class="invalid-feedback">
                                        {{ errors.lastname }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label
                                    :class="{'text-danger': states.name === false}"
                                    class="col-sm-3 col-form-label"
                                    for="name-input"
                                >{{ $t('users.columns.name').ucFirst() }}</label
                                >
                                <div class="col-sm-9">
                                    <input
                                        id="name-input"
                                        v-model="model.name"
                                        :class="{'border-danger': states.name === false}"
                                        :placeholder="$t('users.placeholders.name').ucFirst()"
                                        class="form-control"
                                        name="name"
                                        readonly
                                        type="text"
                                    />
                                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">
                                        {{ errors.name }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label
                                    class="col-sm-3 col-form-label"
                                    for="name-type-input"
                                >{{ $t('users.name_order').ucFirst() }}</label
                                >
                                <div class="col-sm-9">
                                    <div class="form-check form-switch form-switch-md mb-3 ps-0">
                                        <label class="form-check-label"
                                               for="name-type-input">{{ $t('users.western_name_order') }}</label>
                                        <input id="name-type-input" v-model="name_order"
                                               class="form-check-input float-none mx-3" type="checkbox">
                                        <label class="form-check-label"
                                               for="name-type-input">{{ $t('users.eastern_name_order') }}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label
                                    :class="{'text-danger': states.email === false}"
                                    class="col-sm-3 col-form-label"
                                    for="email-input"
                                >{{ $t('users.columns.email').ucFirst() }}</label
                                >
                                <div class="col-sm-9">
                                    <input
                                        id="email-input"
                                        v-model="model.email"
                                        :class="{'border-danger': states.email === false}"
                                        :placeholder="$t('users.placeholders.email').ucFirst()"
                                        class="form-control"
                                        name="email"
                                        type="email"
                                    />
                                    <div :class="{'d-block': states.email === false}" class="invalid-feedback">
                                        {{ errors.email }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label
                                    :class="{'text-danger': states.phone === false}"
                                    class="col-sm-3 col-form-label"
                                    for="phone-input"
                                >{{ $t('users.columns.phone').ucFirst() }}</label
                                >
                                <div class="col-sm-9">
                                    <vue-tel-input
                                        id="phone-input"
                                        v-model="phone"
                                        :inputOptions="{
                                            id: 'phone-input',
                                            placeholder: $t('users.placeholders.phone').ucFirst(),
                                            styleClasses: {'border-danger': states.phone === false}
                                        }"
                                        :invalidMsg="errors.phone"
                                        defaultCountry="hu"
                                        validCharactersOnly
                                        mode="international"
                                    ></vue-tel-input>
                                    <div :class="{'d-block': states.phone === false}" class="invalid-feedback">
                                        {{ errors.phone }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label
                                    :class="{'text-danger': states.is_active === false}"
                                    class="col-sm-3 form-check-label"
                                    for="is-active-input"
                                >{{ $t('users.columns.is_active').ucFirst() }}</label
                                >
                                <div class="col-sm-9">
                                    <input
                                        id="is-active-input"
                                        v-model="model.is_active"
                                        :class="{'border-danger': states.is_active === false}"
                                        class="form-check-input"
                                        name="is_active"
                                        type="checkbox"
                                    />
                                    <div :class="{'d-block': states.is_active === false}" class="invalid-feedback">
                                        {{ errors.is_active }}
                                    </div>
                                </div>
                            </div>

                            <template v-if="!model.id">
                                <div class="row mb-4">
                                    <label class="col-sm-3 form-check-label"
                                           for="custom-password">{{ $t('users.custom_password').ucFirst() }}</label>
                                    <div class="col-sm-9">
                                        <div class="form-check form-switch form-switch-md mb-3 ps-0">
                                            <input
                                                id="custom-password"
                                                class="form-check-input float-none mx-0"
                                                type="checkbox"
                                                v-model="customPassword"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-text">{{ $t('users.custom_password_info').ucFirst() }}</div>
                                    </div>
                                </div>

                                <div ref="trans">
                                    <transition type="animation" @enter="setHeight" @after-leave="height = '100%'">
                                        <div class="row mb-4" v-if="customPassword">
                                            <div class="col-12">
                                                <div class="bg-soft-warning p-3 rounded">
                                                    <div class="row mb-4">
                                                        <div class="col-12">
                                                            <label
                                                                :class="{'text-danger': states.password === false}"
                                                                class="form-label"
                                                                for="password-input"
                                                            >{{ $t('users.columns.password').ucFirst() }}</label>
                                                            <input
                                                                id="password-input"
                                                                v-model="model.password"
                                                                :class="{'border-danger': states.password === false}"
                                                                :placeholder="$t('users.placeholders.password').ucFirst()"
                                                                class="form-control"
                                                                name="password"
                                                                type="password"
                                                            />
                                                            <div :class="{'d-block': states.password === false}"
                                                                 class="invalid-feedback">
                                                                {{ errors.password }}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row mb-4">
                                                        <div class="col-12">
                                                            <label
                                                                :class="{'text-danger': states.password_confirmation === false}"
                                                                class="form-label"
                                                                for="password_confirmation-input"
                                                            >{{ $t('users.columns.password_confirmation').ucFirst() }}</label>
                                                            <input
                                                                id="password_confirmation-input"
                                                                v-model="model.password_confirmation"
                                                                :class="{'border-danger': states.password_confirmation === false}"
                                                                :placeholder="$t('users.placeholders.confirm_password').ucFirst()"
                                                                class="form-control"
                                                                name="password_confirmation"
                                                                type="password"
                                                            />
                                                            <div :class="{'d-block': states.password_confirmation === false}"
                                                                 class="invalid-feedback">
                                                                {{ errors.password_confirmation }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </template>

                            <div class="row justify-content-end">
                                <div class="col-sm-9">
                                    <div>
                                        <button class="btn btn-primary w-md" type="submit" @click.prevent="save">
                                            {{ $t('base.save').ucFirst() }}
                                        </button>
                                        <slot :save="save" name="buttons"></slot>
                                    </div>
                                </div>
                            </div>
                        </b-overlay>
                    </form>
                </div>
                <!-- end card body -->
            </div>
            <!-- end card -->
        </div>
        <!-- end col -->
        <div v-if="showPasswordChange" class="col-xl-6">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t('users.change_password').ucFirst() }}</h4>
                </div>
                <div class="card-body">
                    <form>
                        <div class="row mb-4">
                            <label
                                :class="{'text-danger': states.password === false}"
                                class="col-sm-3 col-form-label"
                                for="current-password-input"
                            >{{ $t('users.current_password').ucFirst() }}</label
                            >
                            <div class="col-sm-9">
                                <input
                                    id="current-password-input"
                                    v-model="change.password"
                                    :class="{'border-danger': states.password === false}"
                                    :placeholder="$t('users.placeholders.current_password').ucFirst()"
                                    class="form-control"
                                    name="current_password"
                                    type="password"
                                />
                                <div :class="{'d-block': states.password === false}" class="invalid-feedback">
                                    {{ errors.password }}
                                </div>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <label
                                :class="{'text-danger': states.new === false}"
                                class="col-sm-3 col-form-label"
                                for="new-password-input"
                            >{{ $t('users.new_password').ucFirst() }}</label
                            >
                            <div class="col-sm-9">
                                <input
                                    id="new-password-input"
                                    v-model="change.new"
                                    :class="{'border-danger': states.new === false}"
                                    :placeholder="$t('users.placeholders.new_password').ucFirst()"
                                    class="form-control"
                                    name="new_password"
                                    type="password"
                                />
                                <div :class="{'d-block': states.new === false}" class="invalid-feedback">{{
                                        errors.new
                                    }}
                                </div>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <label
                                :class="{'text-danger': states.new_confirmation === false}"
                                class="col-sm-3 col-form-label"
                                for="new-confirmation-input"
                            >{{ $t('users.confirm_password').ucFirst() }}</label
                            >
                            <div class="col-sm-9">
                                <input
                                    id="new-confirmation-input"
                                    v-model="change.new_confirmation"
                                    :class="{'border-danger': states.new_confirmation === false}"
                                    :placeholder="$t('users.placeholders.confirm_password').ucFirst()"
                                    class="form-control"
                                    name="new_confirmation"
                                    type="password"
                                />
                                <div :class="{'d-block': states.new_confirmation === false}" class="invalid-feedback">
                                    {{ errors.new_confirmation }}
                                </div>
                            </div>
                        </div>

                        <div class="row justify-content-end">
                            <div class="col-sm-9">
                                <div>
                                    <button class="btn btn-primary w-md" type="submit" @click.prevent="changePassword">
                                        {{ $t('users.change').ucFirst() }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- end card body -->
            </div>
            <!-- end card -->
        </div>
        <!-- end col -->
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Cropper from "../elements/cropper.vue";

export default {
    name: "UserForm",

    emits: ['saved'],

    components: {Cropper},

    props: {
        user: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'user/loading',
            'authenticated': 'user/authenticated',
            'states': 'user/states',
            'errors': 'user/errors'
        }),

        title: function () {
            return this.$t('users.' + (this.model.id ? 'edit_profile' : 'create_user'))
        },

        showPasswordChange: function () {
            return this.user.id === this.authenticated.id
        },

        letter: function () {
            return this.model && this.model.firstname ? this.model.firstname[0] : '?'
        },
    },

    data: function () {
        return {
            model: JSON.parse(JSON.stringify(this.user)),
            change: {
                password: null,
                new: null,
                new_confirmation: null,
                id: null
            },
            name_order: true, // false = western, true = eastern
            cropper: false,
            customPassword: false,
            height: '100%',
            phone: (this.user && this.user.phone) || ''
        }
    },

    methods: {
        uploadImage: function (e) {
            if (e.target.files.length) {
                const file = e.target.files[0];
                this.model.image = file ? URL.createObjectURL(file) : this.model.image;
                this.model.image_file = e.target.files[0]
                this.cropper = true
            }
        },

        save: function () {
            const method = this.model.id ? 'update' : 'create'

            let data = Object.assign({}, this.model, {is_active: this.model.is_active === true ? 1 : 0})

            delete data.image

            return this.$store.dispatch(`user/${method}`, data).then(saved => {
                this.model = JSON.parse(JSON.stringify(saved))
                this.cropper = false
                if (this.authenticated.id === this.model.id) {
                    this.$store.dispatch('user/setAuthenticated', this.model)
                }
                this.$emit('saved')
                if (method === 'create') {
                    this.$router.push(this.$t('routes.' + '/users/:id(\\d+)', {id: this.model.id}).rmValidation())
                }
            })
        },

        changePassword: function () {
            this.$store.dispatch('user/changePassword', Object.assign({}, this.change, {id: this.user.id})).then(() => {
                this.change = {
                    password: null,
                    new: null,
                    new_confirmation: null,
                    id: null
                }
            });
        },

        setHeight: function () {
            if (this.height === '100%') {
                this.height = this.$refs.trans.clientHeight + 'px';
            }
        },

        emptyAddress: function () {
            this.$store.dispatch('address/clearItem')
            this.create = false
        },
    },

    watch: {
        'model.firstname': function (val) {
            this.model.name = this.name_order ? this.model.lastname + ' ' + val : val + ' ' + this.model.lastname
        },

        'model.lastname': function (val) {
            this.model.name = this.name_order ? val + ' ' + this.model.firstname : this.model.firstname + ' ' + val
        },

        name_order: function (val) {
            this.model.name = val ? this.model.lastname + ' ' + this.model.firstname : this.model.firstname + ' ' + this.model.lastname
        },

        user: function (user) {
            this.model = JSON.parse(JSON.stringify(user))
            this.phone = (user && user.phone) || ''
        },

        phone: function (value) {
            this.model.phone = value
            //.replace(/\s|\t|\n|\+/g, '')
        },
    }
}
</script>

<style scoped>
@keyframes slide-left {
    0% {
        width: 0;
        height: 0;
        opacity: 0;
    }
    100% {
        width: 100%;
        height: v-bind(height);
        opacity: 1;
    }
}

@keyframes slide-right {
    0% {
        width: 100%;
        height: v-bind(height);
        opacity: 1;
    }
    100% {
        width: 0;
        height: 0;
        opacity: 0;
    }
}

/* we will explain what these classes do next! */
.v-enter-from {
    width: 0;
    height: 0;
    overflow: hidden;
}

.v-enter-active {
    animation: slide-left 0.5s ease-in;
    overflow: hidden;
}

.v-leave-active {
    animation: slide-right 0.5s ease-in;
    overflow: hidden;
}
</style>
